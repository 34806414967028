import { DetailDeliveryContainer } from "@/components/dashboard/admin/deliveries/detail-delivery";



export default function DeliveryDetailPage() {
   
  return (
    <main className="p-4 md:p-6 mt-16">
      <DetailDeliveryContainer/>
  </main>
  )
}
